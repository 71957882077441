.slide-panel-container{}

.slide-panel-container .cloud-sliding-panel-container{
    background-color: transparent;
}
.slide-panel-container .cloud-sliding-panel-container .panel-glass{

}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom{
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    display: flex;
    flex-direction: column;
}
.slide-panel-container.black-panel .cloud-sliding-panel-container .panel-bottom{
    background-color: #000;
    font-family: 'Montserrat';
    height: auto !important;
    z-index: 1500;
}
.slide-panel-container.blue-panel .cloud-sliding-panel-container .panel-bottom{
    background-color: #0066FF;
    font-family: 'Montserrat';
    height: auto !important;
    z-index: 1500;
    font-weight: 400;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .header-panel{
    height:80px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    flex-grow: 0;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .header-panel a{
    color:#fff;
    font-size: 14px;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .header-panel .title{
    color:#fff;
    font-size: 25px;
    font-weight: 600;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel{
    background-color: #fff;
    flex-grow: 1;
    border-top-left-radius: 21px;
    border-top-right-radius: 21px;
    padding: 25px 40px;
    padding-bottom: 110px;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel .icons-navigation{

}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel .icons-navigation .nav-link{
    color:#000;
    font-size: 44px;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel .icons-navigation .nav-link .icon{}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel .icons-navigation .nav-link .icon-title{
    font-size: 12px;
    font-weight: 600;
}

.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order{}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .feed-img{
    text-align: center;
    margin-bottom: 25px;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .feed-img img{
    max-width: 100%;
    width: 180px;
    border-radius: 20px;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .name{
    text-align: center;
    font-family: 'Open Sans';
    font-size: 15px;
    margin-bottom: 10px;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .total-price{
    text-align: center;
    font-family: 'Open Sans';
    font-size: 15px;
    margin-bottom: 20px;

}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .total-price .title{
    font-weight: 700;
    margin-right: 20px;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .total-price .price{}

.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .form-group label{
    color: #0066FF;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .form-group .form-control.bordered{
    border-radius: 10px;
    border:1px solid #7f7f7f;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order #card-element{
    border-radius: 10px;
    border:1px solid #7f7f7f;
    padding: .5rem .75rem;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .form-group .error{
    color:#f00;
}
.slide-panel-container .cloud-sliding-panel-container .panel-bottom .body-panel  .exhibit-place-order .form-group .form-control.bordered.error{
    border-color: #f00;
}